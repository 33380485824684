import { useRouter } from "next/router";

export function Metas() {
  const { asPath } = useRouter();
  const cleanPath = asPath.split("#")[0].split("?")[0];
  return (
    <>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link rel="profile" href="https://gmpg.org/xfn/11" />
      <meta
        name="robots"
        content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
      />
      <link rel="canonical" href="https://agenciacaravela.com.br/" />
      <meta property="og:locale" content="pt_BR" />
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content={`https://agenciacaravela.com.br${cleanPath}`}
      />
      <meta property="og:site_name" content="Agência Caravela - BR" />
      <meta
        property="article:publisher"
        content="https://facebook.com/agcaravela"
      />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@agenciacaravela" />
      <meta name="twitter:label1" content="Est. tempo de leitura" />
      <meta name="twitter:data1" content="10 minutos" />
      <meta
        property="og:image"
        content="https://agenciacaravela.com.br/img/home/fundo.png"
      />

      <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
      <meta name="msapplication-TileColor" content="#00ffb2" />

      <meta
        name="og:image"
        content="https://agenciacaravela.com.br/img/icone/marujos.svg"
      />
      <meta
        data-n-head="ssr"
        data-hid="twitter:image"
        name="twitter:image"
        content="https://agenciacaravela.com.br/img/home/fundo.png"
      />

      <meta
        data-n-head="ssr"
        data-hid="twitter:card"
        name="twitter:card"
        content="summary_large_image"
      />
      <meta
        itemProp="thumbnailUrl"
        content="https://agenciacaravela.com.br/img/icone/marujos.svg"
      />
      <meta property="og:image:type" content="image/jpeg" />
      <meta property="og:image:width" content="300" />
      <meta property="og:image:height" content="300" />

      <link
        data-n-head="ssr"
        rel="icon"
        type="image/x-icon"
        href="https://agenciacaravela.com.br/fav/apple-touch-icon.png"
      />

      <meta
        name="google-site-verification"
        content="hZbHPRbn1Ah0hjhloCQwL0qfS7WKoH0OVn5jww98zaI"
      />
    </>
  );
}
